<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <!-- FORM HEADER -->
      <div>
        <!-- title -->
        <span class="send-code-title">{{ $t("confirm_data") }}</span>

        <!-- subtitle -->
        <p class="send-code-subtitle">
          {{ $t("code_sent_text") }}
        </p>
      </div>

      <div>
        <h3 class="email-title">{{ $t("code") }}</h3>

        <v-text-field
          data-test="NewUser:ValidateRegister:ConfirmPin"
          @keydown.space.prevent
          background-color="#D1D5DB"
          color="#000"
          solo
          flat
          v-model="code"
          placeholder="XXXX"
          class="my-input-style mt-2"
          :rules="[required]"
          maxLength="4"
        />
      </div>

      <div class="recover-btn-container">
        <v-btn
          text
          color="primary"
          :loading="anotherCodeLoading"
          class="recover-btn"
          @click="sendAnotherCode"
          >{{ $t("send_another_code") }}</v-btn
        >
      </div>

      <v-btn
        data-test="NewUser:ValidateRegister:BtnConclude"
        type="submit"
        color="primary"
        class="btn-login"
        :loading="loading"
        ><span>{{ $t("conclude") }}</span></v-btn
      >
    </v-form>
    <v-alert class="ma-2" v-if="alert" :type="success ? 'success' : 'error'">
      {{ alert }}
    </v-alert>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "ValidateRegister",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    success: false,
    anotherCodeLoading: false,
    error: null,
    code: null,
  }),
  async created() {},
  props: {
    userEmail: String,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    closeAlertSendAnotherCode() {
      // mesmo tempo que o toast para fechar
      setTimeout(() => {
        this.success = false;
        this.alert = null;
      }, 6000);
    },
    async sendAnotherCode() {
      this.anotherCodeLoading = true;
      this.alert = false;

      let payload = {
        Email: this.userEmail,
      };

      await this.apiService
        .postRequest("user/send-another-code", payload, false)
        .then((result) => {
          this.success = true;
          this.alert = this.$t("another_code_sent");

          this.closeAlertSendAnotherCode();
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.anotherCodeLoading = false;
    },
    async checkForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      let result = this.$refs.form.validate();

      if (result) {
        await this.checkUserCode();
      } else {
        this.loading = false;
      }
    },
    async checkUserCode() {
      this.alert = null;
      this.loading = true;

      await this.apiService
        .postRequest("user/check-register-code", {
          code: parseInt(this.code),
          lang: this.$i18n.locale,
          user: { email: this.userEmail },
        })
        .then((result) => {
          result = result.content;
          const user = JSON.stringify(result.user);

          this.$store.commit("SET_LOGGED_USER", user);
          this.$store.commit("SET_CURRENT_TOKEN", result.token);
          this.$store.commit("SET_EXPIRES_TOKEN", result.expires);
          this.$store.commit("SET_SIGNED_NDA", result.signed_nda);
          this.$router.push("/pipeline");
        })
        .catch((error) => {
          this.success = false;
          this.alert = this.$t(error.body.message);
          this.code = null;
        });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.my-input-style >>> .v-text-field__slot input,
.my-input-style >>> .v-input__icon button {
  color: var(--dark);
  width: 100%;
}

.my-input-style >>> .v-text-field__slot input::placeholder {
  color: #9c9c9c;
}

.btn-login {
  width: 100%;
  background-color: #1a56db !important;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 500;
  text-align: center;
  padding: 10px 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.send-code-title {
  color: #111928;
  font-weight: bold;
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.recover-btn {
  font-weight: bold;
  font-size: 12px !important;
  color: #1c64f2;
  margin-top: 20px;
  display: flex;
  padding: 4px 0;
  flex-direction: column;
  align-items: center;
}

.recover-btn-container {
  display: grid;
  place-items: center;
}

.send-code-subtitle {
  color: #6b7280;
  font-weight: 500;
  font-size: 14px;
  display: block;
  text-align: center;
  margin-bottom: 32px;
}

.email-title,
.password-title {
  font-weight: 500;
  font-size: 14px;
  color: #111928;
}

.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>
