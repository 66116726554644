<template>
  <div style="width: 100%; overflow: auto; min-height: 100vh">
    <div class="container-login">
      <!-- primeira coluna -->
      <div class="first-login-column">
        <!-- login form -->
        <div class="login-form">
          <!-- login form content - segura o conteúdo do form + header -->
          <div class="login-form-content">
            <!-- div para alinhar ao centro -->
            <div>
              <!-- logo mobile -->
              <div class="dealboard-logo-mobile">
                <img
                  src="../../../../assets/dealboard-logo-black.svg"
                  alt="dealboard"
                />
              </div>
              <div class="login-form-header" v-if="!validating">
                <span class="title">{{ $t("welcome") }}</span>
                <span class="sub-title"
                  >{{ $t("welcome_dxa_login_content") }}
                </span>
              </div>
              <div class="login-form-content">
                <v-form
                  @submit.stop.prevent="checkForm()"
                  ref="form"
                  v-model="valid"
                  :lazy-validation="lazy"
                  v-if="!validating"
                >
                  <div class="div_email">
                    <h3 class="email_title">E-mail</h3>
                    <v-text-field
                      solo
                      flat
                      background-color="#D1D5DB"
                      color="#000"
                      :rules="[required]"
                      v-model="user.Email"
                      class="my-input-style mt-2"
                      data-test="Login:Login:Email"
                      placeholder="name@example.com"
                    />
                  </div>

                  <div class="div_password">
                    <h3 class="password_title">{{ $t("password") }}</h3>
                    <v-text-field
                      solo
                      flat
                      background-color="#D1D5DB"
                      color="#000"
                      :rules="[required]"
                      v-model="user.UserPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      name="password"
                      ref="user.UserPassword"
                      @click:append="show1 = !show1"
                      class="my-input-style mt-2"
                      data-test="Login:Login:Password"
                      placeholder="********"
                    />
                  </div>

                  <div class="forgot-password-container">
                    <v-btn
                      class="recover_btn"
                      text
                      @click="$router.push({ name: 'ForgotPasswordClient' })"
                      data-test="Login:Login:ForgotPassword"
                      >{{ $t("forgot_pwd") }}</v-btn
                    >
                  </div>

                  <pages-btn
                    type="submit"
                    block
                    dark
                    :loading="loading"
                    :color="color"
                    class="btn-login"
                    data-test="Login:Login:SingIn"
                    >Login</pages-btn
                  >

                  <!-- btn mobile forgot password -->
                  <div class="forgot-password-container-mobile">
                    <span
                      @click="$router.push({ name: 'ForgotPasswordClient' })"
                      data-test="Login:Login:ForgotPassword"
                      class="recover_btn_mobile"
                    >
                      {{ $t("forgot_pwd") }}
                    </span>
                  </div>
                </v-form>
                <ValidateRegister v-if="validating" :userEmail="userEmail" />
              </div>
            </div>
          </div>

          <!-- copyright -->
          <div class="login-copyright">
            <p class="text">{{ $t("copyright_dealboard") }}</p>
          </div>
        </div>
      </div>
      <!-- segunda coluna -->
      <div class="second-login-column">
        <!-- content -->
        <div class="login-text-column">
          <div class="login-text-column-container">
            <div class="dealboard-logo">
              <img
                src="../../../../assets/dealboard-logo-white.svg"
                alt="dealboard"
              />
            </div>
            <span class="title">{{ $t("dealboard_login_title") }}</span>
            <span class="subtitle">{{ $t("dealboard_login_subtitle") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import PagesBtn from "@/views/pages/components/Btn.vue";
import ValidateRegister from "../register/tabs/ValidateRegister";
import * as Sentry from "@sentry/vue";
import { mapGetters, mapMutations } from "vuex";
import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;
import { LanguageEnum } from "@/shared/enums/LanguageEnum";
import { getKeyByValue } from "@/shared/helpers/objectHelper";
import { UserTypeEnum } from "@/shared/enums/UserType";
import mixpanel from "mixpanel-browser";
import { bankerRegisterWrongUrl } from "@/shared/helpers/bankerRegisterHelper";
import { mainTheme } from "@/style/theme.js";

export default {
  name: "PagesLogin",
  components: {
    "pages-btn": PagesBtn,
    ValidateRegister,
  },
  async created() {
    this.userRouterProp = this.userRouter;
    // acessando o histórico de rotas
    const history = this.$router.history;

    // se ele vier de alguma rota que esteja com /auth, vamos forçar o reload da página, para mostrar o botão de idioma.
    if (
      history.current.meta.from &&
      history.current.meta.from.includes("/auth")
    ) {
      this.loading = true;
      window.location.reload();
    }

    this.pageLoading = true;
    const theme = mainTheme;
    this.$vuetify.theme.themes = {
      dark: theme,
      light: theme,
    };
    localStorage.clear();
    if (this.lang) {
      localStorage.setItem("lang", this.lang);
    }
    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.partner_login) {
        var client = new ApiService();
        await client
          .getRequest(
            `user/get-external-user/${this.$route.query.partner_login}`
          )
          .then((result) => {
            if (!result.registered) {
              this.$router.push({
                name: "RegisterClient",
                query: { partner_login: this.$route.query.partner_login },
              });
              return;
            } else {
              this.configs = result.configs;
              if (this.configs.image) {
                this.configs.image = this.configs.image;
              }
              this.user.Email = result.userEmail;
              this.pageLoading = false;
            }
          })
          .catch(function (err) {});
      } else {
        if (Object.keys(this.$route.query).length >= 2) {
          var client = new ApiService();
          await client
            .getRequest(`partner/get/${this.$route.query.partner_name}`)
            .then((result) => {
              this.$router.push({
                path: "auth/welcome-page",
                query: { partner: this.$route.query.partner_name },
              });
              this.configs = result.content;
              if (this.configs.CompanyLogoPath) {
                this.configs.CompanyLogoPath = this.configs.CompanyLogoPath;
              }
            })
            .catch((err) => {
              if (err.status === 400) {
                bankerRegisterWrongUrl();
              }
            });
          this.pageLoading = false;
        } else if (Object.keys(this.$route.query).length == 1) {
          var client = new ApiService();
          await client
            .getRequest(`partner/get/${this.$route.query.partner_name}`)
            .then((result) => {
              this.$router.push({
                path: "auth/welcome-page",
                query: { partner: this.$route.query.partner_name },
              });
              this.configs = result.content;
              if (this.configs.CompanyLogoPath) {
                this.configs.CompanyLogoPath = this.configs.CompanyLogoPath;
              }
            })
            .catch((err) => {
              if (err.status === 400) {
                bankerRegisterWrongUrl();
              }
            });
          this.pageLoading = false;
        } else {
          this.pageLoading = false;
        }
      }
    } else {
      this.pageLoading = false;
    }
  },
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    pageLoading: false,
    valid: true,
    validating: false,
    validateUser: null,
    lazy: false,
    show1: false,
    error: null,
    user: {
      Email: null,
      UserPassword: null,
    },
    userEmail: null,
    configs: null,
    userRouterProp: null,
    requiredReloadPage: true,
  }),
  computed: {
    color() {
      if (
        this.configs &&
        this.$route.query.partner_name != "DXAInvestPrivate"
      ) {
        if (this.configs.CompanyColor) {
          return this.configs.CompanyColor;
        }

        return this.configs.color;
      } else {
        const documentStyle = window.getComputedStyle(document.documentElement);
        const documentPrimaryColor =
          documentStyle.getPropertyValue("--primary");

        return documentPrimaryColor;
      }
    },
    ...mapGetters({
      lang: "get_language",
      userRouter: "get_redirect_user_router",
    }),
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  watch: {
    userRouterProp() {
      const authRouter =
        this.$route.path === "/auth" || this.$route.path === "/auth/";
      const messagePt = "Faça login para continuar";
      const messageEn = "Login to continue";

      if (this.userRouter && authRouter) {
        const lang = localStorage.getItem("lang");
        this.$toast.warning(lang === "pt" ? messagePt : messageEn);
      }
    },
  },
  methods: {
    ...mapMutations({
      setUserRouter: "SET_REDIRECT_USER_ROUTER",
    }),
    getUserLang() {
      this.apiService
        .getRequest("user/language")
        .then((response) => {
          let language = getKeyByValue(LanguageEnum, response.content);
          this.$store.commit("SET_LANGUAGE", language);
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    logar_chat(name, email) {
      octadesk.chat.login({
        name: name, // nome do seu usuário
        email: email, // email do seu usuário
      });
    },
    checkForm: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        this.login();
      } else {
        this.loading = false;
      }
    },
    getUserTypeLabel(userType) {
      const literal = {
        [UserTypeEnum.Investor]: "Investor",
        [UserTypeEnum.Admin]: "Admin",
        [UserTypeEnum.Partner]: "Partner",
        [UserTypeEnum.Translator]: "Translator",
        [UserTypeEnum.FinancialAnalyst]: "FinancialAnalyst",
        [UserTypeEnum.ContentAnalyst]: "ContentAnalyst",
        [UserTypeEnum.Banker]: "Banker",
        [UserTypeEnum.AlphaAnalyst]: "AlphaAnalyst",
      };

      return literal[userType];
    },
    login: function () {
      this.error = null;
      var client = new ApiService();
      client
        .postRequest("user/token", this.user, false)
        .then((result) => {
          this.loading = false;
          const user = JSON.stringify(result.user);
          Sentry.setUser({ email: result.user.email });

          // agora que temos o controle de onboarded e assinatura de termo no react.
          // ele só vai para o renewContracts, quando a prop redirectToReactApp for false,
          // nesse caso, ele não tema cesso a plataforma banker, então faz o fluxo de onboarded e renovação de contrato, pelo vue.
          if (
            result.user.type === UserTypeEnum.Banker &&
            !result.user.redirectToReactApp
          ) {
            if (result.user.hasRenewContractToSign === true) {
              localStorage.setItem("signContractLater", false);
              if (result.user.isOnboarded === true) {
                this.$router.push("/renewContracts");
              }
            } else localStorage.setItem("signContractLater", true);
          }

          if (result.user.type === UserTypeEnum.Partner) {
            const theme = {
              primary: "#197DFF",
              secondary: "#7733FF",
              dark: "#000000",
              info: "#197DFF",
              white: "#ffffff",
            };
            this.$vuetify.theme.themes = {
              dark: theme,
              light: theme,
            };
          }

          // DISPARANDO O EVENTO QUANDO O USUÁRIO LOGA
          mixpanel.track("User_login", {
            user_id: result.user.id,
            user_type: this.getUserTypeLabel(result.user.type),
            partner_id: result.user.partnerId ? result.user.partnerId : "",
            banker_id: result.user.bankerId ? result.user.bankerId : "",
          });

          this.$store.commit("SET_LOGGED_USER", user);
          this.$store.commit("SET_CURRENT_TOKEN", result.token);
          this.$store.commit("SET_EXPIRES_TOKEN", result.expires);
          this.$store.commit("SET_SIGNED_NDA", result.signed_nda);

          if (result.user.email) {
            window.$crisp.push(["set", "user:email", [result.user.email]]);
          }
          if (result.user.fullName) {
            window.$crisp.push([
              "set",
              "user:nickname",
              [result.user.fullName],
            ]);
          }
          if (result.user.image) {
            window.$crisp.push([
              "set",
              "user:avatar",
              [this.gs.get_photo_path(result.user.image)],
            ]);
          }
          if (
            result.user.type == UserTypeEnum.Investor &&
            result.user.partnerName
          ) {
            window.$crisp.push([
              "set",
              "session:data",
              [
                [
                  ["name", result.user.fullName],
                  ["email", result.user.email],
                  ["partner", result.user.partnerName],
                  ["registerDate", result.user.registerDate],
                  ["editUrl", result.user.editUserUrl],
                ],
              ],
            ]);
          }

          // se for manager, vai direto para a plataforma react (manager)
          if (result.user.type === UserTypeEnum.ManagerUser) {
            this.$router.push("/app-redirect");
            return;
          }

          this.getUserLang();

          this.$refs.form.reset();
          if (this.$route.query.partner_name) {
            this.$router.push(`${this.$route.query.partner_name}/pipeline`);
          } else if (this.$route.query.company_id) {
            this.$router.push(
              `/companies/briefing/${this.$route.query.company_id}`
            );
          } else {
            if (this.userRouter) {
              this.$router.push(this.userRouter).catch((failure) => {});

              // Retirando a prop do vuex após o redirect para a rota.
              // Para não mostrar o toast de alerta quando o usuário fizer o logout da plataforma.
              this.setUserRouter(null);
            } else {
              this.$router.push("/").catch((failure) => {});
            }
          }
        })
        .catch((err) => {
          let errorMsg = "incorrect_login";

          if (err.body.message == "user_blocked_pin") {
            const email = err.body.user_email;

            this.validateUser = { Email: email };
            this.userEmail = email;
            this.validating = true;
            errorMsg = "user_blocked_pin";
          }

          this.$toast.error(this.$t(errorMsg));
          this.loading = false;
          localStorage.removeItem("token");
          localStorage.removeItem("expires");
          localStorage.removeItem("user");
          this.$refs.form.reset();
        });
    },
  },
};
</script>

<style>
.crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi {
  background-color: var(--primary) !important;
}

.app_bar {
  z-index: 1;
}
</style>

<style scoped>
.dxa_logo {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  margin-bottom: 65px;
}

.div_logo {
  text-align: center;
}

.div_welcome {
  display: flex;
  flex-direction: column;
}

.welcome_title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  color: var(--white);
}

.welcome_content {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--white);
}

.div_email,
.div_password {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.email_title,
.password_title {
  font-weight: 500;
  font-size: 14px;
  color: #111928;
}

.recover_btn {
  font-weight: bold;
  font-size: 12px !important;
  color: #1c64f2;
  text-align: center;
}

.recover_btn_mobile {
  font-weight: bold;
  font-size: 12px !important;
  color: #1c64f2;
  margin-top: 20px;
  display: block;
  padding: 4px 0;
}

.btn-login {
  width: 100%;
  background-color: #1a56db !important;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 500;
  text-align: center;
  padding: 10px 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.forgot-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.access_btn {
  color: var(--white);
  position: relative;
  border-radius: 16px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  min-width: 80% !important;
  margin-right: auto;
}

.my-input-style >>> .v-text-field__slot input,
.my-input-style >>> .v-input__icon button {
  color: var(--dark);
  width: 100%;
}

.my-input-style >>> .v-text-field__slot input::placeholder {
  color: #9c9c9c;
}

.crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi {
  background-color: var(--primary) !important;
  margin-bottom: 165% !important;
}

/* new login page styles */
.container-login {
  background: #fff;
  width: 100%;
  display: flex;
}

.first-login-column {
  min-height: 100vh;
  width: 50%;
}

.second-login-column {
  background: #1c64f2;
  width: 50%;
  min-height: 100vh;
}

.login-form-header span.title {
  color: #111928;
  font-weight: bold;
  font-size: 20px !important;
  margin-bottom: 10px;
}

.login-form-header span.sub-title {
  color: #6b7280;
  font-weight: 500;
  font-size: 14px;
  display: block;
}

.login-form-header {
  margin-bottom: 20px;
}

.login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 53.25px;
  padding-left: 53.25px;
}

.login-copyright p.text {
  font-size: 14px !important;
  color: #6b7280;
  text-align: center;
  padding-bottom: 12px;
}

.login-form-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-text-column-container span.title {
  font-size: 36px !important;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 12px;
  max-width: 576px;
  line-height: 36px;
  display: block;
}

.login-text-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 53.25px;
  padding-right: 53.25px;
  height: 100%;
}

.login-text-column-container span.subtitle {
  font-size: 16px !important;
  color: #e5e7eb;
  line-height: 24px;
  max-width: 576px;
  display: block;
}

.dealboard-logo {
  max-width: 142px;
  margin-bottom: 32px;
}

.forgot-password-container-mobile {
  display: none;
}

.dealboard-logo-mobile {
  display: none;
}

@media not all and (min-width: 1264px) and (max-width: 1903px) {
  .login_container {
    margin-right: 0;
  }
}

@media only screen and (max-width: 769px) {
  .dxa_logo {
    margin-bottom: 32px;
  }

  .second-login-column {
    display: none;
  }

  .first-login-column {
    width: 100%;
  }

  .login-form {
    padding: 24px;
  }

  .forgot-password-container {
    display: none;
  }

  .forgot-password-container-mobile {
    display: block;
  }

  .dealboard-logo-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dealboard-logo-mobile img {
    max-width: 142px;
    margin-bottom: 80px;
  }
}
</style>
